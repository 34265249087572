import { render, staticRenderFns } from "./CardsCountStatistics.vue?vue&type=template&id=29c1fcb3&scoped=true&"
import script from "./CardsCountStatistics.vue?vue&type=script&lang=js&"
export * from "./CardsCountStatistics.vue?vue&type=script&lang=js&"
import style0 from "./CardsCountStatistics.vue?vue&type=style&index=0&id=29c1fcb3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29c1fcb3",
  null
  
)

export default component.exports